AOS.init({
    easing: 'ease-out',
    once: true
});

window.onload = () => {
    const WINDOW_WIDTH = window.innerWidth;
    if (WINDOW_WIDTH <= 1080) {
        let brands = document.querySelector("#brands .brands");

        const MAX_OFFSET = brands.offsetWidth - WINDOW_WIDTH;
        let stepSize = MAX_OFFSET / 1000;

        let margin = 0;

        let clock = window.setInterval(() => {
            margin += stepSize;
            if (margin > MAX_OFFSET) {
                margin = MAX_OFFSET;
                stepSize *= -1;
            } else if (margin < 0) {
                margin = 0;
                stepSize *= -1;
            }
            brands.style.marginLeft = -margin + "px";
        }, 20);
    }
};